import React from "react";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { FiX, FiMenu } from "react-icons/fi";
import logo from '../../public/assets/images/logo/devsmatrix.svg'

function HeaderHome(props) {
  const { colorbg = "nocolor" } = props;

  let logoUrl = (
    <ReactSVG src={logo} />
    // <img src="/assets/images/logo/devs1.jpg" alt="Devs Matrix" />
  );

  const menuTrigger = () => {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  };

  const CLoseMenuTrigger = () => {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  };

  return (
    <div>
      <header
        className={`header-area formobile-menu header--transparent ${colorbg} `}
      >
        <div className="header-wrapper" id="header-wrapper">
          <div className="header-left">
            <div className="logo">
              <a href="/" alt="Devs Matrix">
                {logoUrl}
              </a>
            </div>
          </div>
          <div className="header-right">
            <nav className="mainmenunav d-lg-block">
              <ul className="mainmenu">
                <li >
                  <Link to="/">Home</Link>
                </li>

                <li>
                  <Link to="/contact-us">Contact</Link>
                </li>
              </ul>
            </nav>
            <div className="header-btn">
              <Link
                to="/jobs"
                className="rn-btn"
                href="https://google.com"
              >
                <span>Career</span>
              </Link>
            </div>
            {/* Start Humberger Menu  */}
            <div className="humberger-menu d-block d-lg-none pl--20">
              <span onClick={menuTrigger} className="menutrigger text-white">
                <FiMenu />
              </span>
            </div>
            {/* End Humberger Menu  */}
            <div className="close-menu d-block d-lg-none">
              <span onClick={CLoseMenuTrigger} className="closeTrigger">
                <FiX />
              </span>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default HeaderHome;
