import React from "react";
import HeaderHome from "./HeaderHome";
import ContactThree from "./ContactThree";
import { FiHeadphones, FiMail, FiMapPin } from "react-icons/fi";

function ContactUs() {
  return (
    <div>
      <HeaderHome colorbg="color-bg" />

      <div className="pt--120 pb--190 bread-bg" data-black-overlay="5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="rn-page-title text-center pt--100">
                <h2 className="title theme-gradient">Let's Meet</h2>
                <p>Your mail makes a difference!</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Start Contact Top Area  */}
      <div className="rn-contact-top-area ptb--120 blend">
        <div className="container">
          <div className="row">
            {/* Start Single Address  */}
            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
              <div className="rn-address">
                <div className="icon">
                  <FiHeadphones />
                </div>
                <div className="inner">
                  <h4 className="title">Contact With Phone Number</h4>
                  <p>
                    <a href="tel:+057 254 365 456">+92 30546909 69</a>
                  </p>
                  <p>
                    <a href="tel:+856 325 652 984">+92 32429044 44</a>
                  </p>
                </div>
              </div>
            </div>
            {/* End Single Address  */}

            {/* Start Single Address  */}
            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
              <div className="rn-address">
                <div className="icon">
                  <FiMail />
                </div>
                <div className="inner">
                  <h4 className="title">Email Address</h4>
                  <p>
                    <a href="mailto:admin@gmail.com">contact@devsmatrix.com</a>
                  </p>
                  <p>
                    <a href="mailto:example@gmail.com">mail@devsmatrix.com</a>
                  </p>
                </div>
              </div>
            </div>
            {/* End Single Address  */}

            {/* Start Single Address  */}
            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
              <div className="rn-address">
                <div className="icon">
                  <FiMapPin />
                </div>
                <div className="inner">
                  <h4 className="title">Location</h4>
                  <p>
                    Street # 1, Murad Colony, Main <br />
                    Faislabad Road, Sargodha{" "}
                  </p>
                </div>
              </div>
            </div>
            {/* End Single Address  */}
          </div>
        </div>
      </div>
      {/* End Contact Top Area  */}

      <main className="page-wrapper">
        {/* Start Contact Form  */}
        <div className="rn-contact-form-area ptb--120 bg_color--s">
          <ContactThree
            contactTitle="Contact Us"
            contactImages="/assets/images/about/conorm.png"
          />
        </div>
        {/* Start Contact Form  */}
      </main>
    </div>
  );
}

export default ContactUs;
