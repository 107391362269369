import React from "react";
import HeaderHome from "./HeaderHome";
import { JobList } from "./Jobcontent";



function Jobs() {

  return (
    <div>
      <HeaderHome colorbg="color-bg" />

      <div className="pt--120 pb--190 bread-bg" data-black-overlay="5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="rn-page-title text-center pt--100">
                <h2 className="title theme-gradient">Jobs</h2>
                <p>Your Job Step Begins Here</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="service-area ptb--120 bg_color--5">
        <div className="container">
          <div className="row service-one-wrapper">
            {JobList.map((val, i) => (
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12"
                key={i}
              >
                {/* <a href="/service-details"> */}
                
                  <div className="service service__style--2">
                  <a
                  href={val.link}
                 >
                  
                    <div className="icon">{val.icon}</div>
                    <div className="content">
                      <h3 className="title">{val.title}</h3>
                      <p>{val.description}</p>
                    
                    </div>
                    </a>
                  </div>
                  

              </div>
            ))}
          </div>
        </div>
      </div>

      
    </div>
  );
}

export default Jobs;
