export const JobList = [
  {
    icon: "One",
    title: "Digital Marketing Expert",
    description:
      "We're looking for a freelance Social Media Manager to join our team. This person should be a true self-starter, love to stay on top of new social platforms features and trends, and must be both organized and creative.",
    link:"https://uk.thejobnexus.com/job/digital-marketing-expert-3"
  },
  {
    icon: "Two",
    title: "Full Stack Developer",
    description:
      "Looking for a professional responsible for working on both front-end and back-end development processes. 2+ Years Of Web Development Experience Required.",
    link:"https://uk.thejobnexus.com/job/full-stack-developer-2"
  },
  {
    icon: "Three",
    title: "Global Partnership Manager",
    description:
      "As Global Partnerships Manager, you will drive proactive account management, insights, and support to Devs Matrix Global partners.You will play a key role in managing new and existing agency partnerships, communicating with internal teams and agencies and supporting the overall revenue.",
    link:"https://uk.thejobnexus.com/job/partnership-manager-4"
  },
  {
    icon: "Four",
    title: "Data Entry Expert",
    description:
      "A person who can take responsibility of managing and entering data in software company following appropriate rules and regulations.",
    link:"https://uk.thejobnexus.com/job/data-entry-expert-1"
  },
];


