
import React,{Suspense} from "react";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";

import Loader from "../component/Loader";

const HeaderHome  = React.lazy(() => import("../component/HeaderHome"));
const Footer = React.lazy(() => import("../component/Footer"));
const Helmet  = React.lazy(() => import("../component/Helmet"));
const Testimonial  = React.lazy(() => import("../elements/Testimonial"));
// import BlogContent from "../elements/BlogContent";
const Service  = React.lazy(() => import("../elements/Service"));
const Blog  = React.lazy(() => import("../component/Blog"));
const About  = React.lazy(() => import ("../component/About"));
const Portfolio = React.lazy(() => import("../component/HomePort"));
const SliderOne = React.lazy(() => import("../component/SliderOne"));





function MainDemo() {
  
  return (
    <div>
      <Suspense fallback={<Loader />}>
     
      
      <Helmet pageTitle="Devs Matrix" />
      <HeaderHome colorbg="color" />
      
      <div className="slider-wrapper">
      <SliderOne />
      </div>
      
      <div className="about-area about-position-top pb--120">
      <About />  
      </div>


      <div className="service-area ptb--80  bg_image bg_image--3">
        <div className="container">
        
          <Service />
         
        </div>
      </div>


      <div className="portfolio-area ptb--120 bg_color--1">
        <div className="portfolio-sacousel-inner mb--55">
       
          <Portfolio />
          
        </div>
      </div>



      <div className="rn-testimonial-area bg_color--5 ptb--120">
        <div className="container">
        
          <Testimonial />
         
        </div>
      </div>



      <div className="rn-blog-area pt--120 bg_color--1 mb-dec--30">
      
        <Blog />
        
      </div>
      {/* End Blog Area */}

      <br />
      <br />




      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}
      
      <Footer />
    

      </Suspense>

     

      

     
      {/* Start Portfolio Area */}
      
      {/* End Portfolio Area */}

      {/* Start Testimonial Area */}
      
      {/* End Testimonial Area */}

      {/* Start Blog Area */}
      

     
      {/* Start Back To Top */}
      
    </div>
  );
}

export default MainDemo;
