import React, { useState } from "react";
import axios from "axios";
import FlashMessage from "react-flash-message";
import BarLoader from "react-spinners/BarLoader";

function ContactThree(props) {
  // const [file, setFile] = useState(0);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [number, setNumber] = useState(0);
  const [flash, setFlash] = useState(false);
  const [designation, setDesignation] = useState(0);
  const [query, setQuery] = useState('');
  const [loader,setLoader] = useState(false);

  // const handleFile = (event) => {
  //   setFile(event.target.files[0]);
  // };

  const handleSubmit =(event) => {
    
    setFlash(false);
    event.preventDefault();
    const data = new FormData();
    setLoader(true)
    // data.append("file", file);


    data.append("name", name);
    data.append("email", email);
    data.append("number", number);
    data.append("designation", designation);
    data.append("query", query);
    data.append("token",process.env.REACT_APP_OUR_TOKEN)
    
    
    axios.post(process.env.REACT_APP_LINK, data).then((res) => {
     
      if (res.statusText) {
        setLoader(false);
        setFlash(true);
        
      }
      
    });
  };

  return (
    <div className="contact-form--1">
      <div className="container">
        <div className="row row--35 align-items-start">
          <div className="col-lg-6 order-2 order-lg-1">
            <div className="section-title text-left mb--50">
              <h2 className="title">{props.contactTitle}</h2>
              <p className="description">
                {/* I am available for freelance work. Connect with me via phone:{" "}
                <a href="tel:+8801923088574">01923 088574</a> or email:
                <a href="mailto:admin@example.com"> admin@example.com</a>{" "} */}
              </p>
            </div>
            <div className="form-wrapper">
              <form onSubmit={handleSubmit}>
                <label htmlFor="item01">
                  Full Name:
                  <input
                    type="text"
                    name="name"
                    id="item01"
                    placeholder="Enter Your Name"
                    required
                    onChange={(e) => setName(e.target.value)}
                  />
                </label>

                <label htmlFor="item02">
                  Company Email
                  <input
                    type="email"
                    name="email"
                    id="item02"
                    required
                    placeholder="Enter Your Company Email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </label>

                <label htmlFor="item03">
                  Designation:
                  <input
                    type="text"
                    name="number"
                    id="item03"
                    required
                    placeholder="Enter Your Designation"
                    onChange={(e) => setDesignation(e.target.value)}
                  />
                </label>

                <label htmlFor="item04">
                  Contact Number:
                  <input
                    type="text"
                    name="number"
                    id="item04"
                    required
                    placeholder="Enter Your Phone Number"
                    onChange={(e) => setNumber(e.target.value)}
                  />
                </label>

                <label htmlFor="item05">
                  Query:
                  <textarea
                    rows="4"
                    cols="50"
                    name="comment"
                    form="usrform"
                    placeholder="How Can We Help You!"
                    onChange={(e) => setQuery(e.target.value)}
                  />
                </label>
                {/* <label htmlFor="item04">
                  Upload Your CV / Resume
                  <input
                    type="file"
                    name="file"
                    id="item04"
                    accept=".pdf"
                    onChange={handleFile}
                  />
                </label> */}
                <button
                  className="rn-button-style--2 btn-solid"
                  type="submit"
                  value="submit"
                  name="submit"
                  id="mc-embedded-subscribe"
                >
                  Submit
                </button>
                <br></br>
                <br></br>
                {loader && <BarLoader color="red"/> }

                {flash && (
                  <div>
                    <FlashMessage duration={5000}>
                      <strong>Email Sent!</strong>
                      <p>We will reach you out soon.</p>
                    </FlashMessage>
                  </div>
                )}
              </form>
            </div>
          </div>
          <div className="col-lg-6 order-1 order-lg-2">
            <div className="mb_md--30 mb_sm--30">
              <img src={`${props.contactImages}`} alt="devsmatrix" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactThree;
